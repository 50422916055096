import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { DashboardVariants } from './components/Dashboard'
import { FileScanOptionKeys } from './store/uploads/types'

const resources = {
  en: {
    translation: {
      site__name: 'Expert Hub',
      currency: '$',
      yes: 'Yes',
      no: 'No',
      confirm: 'Confirm',
      today: 'Today',
      tomorrow: 'Tomorrow',
      // These are date-fns format strings
      date_format: 'MMMM dd, yyyy',
      time_format: 'hh:mm a',
      pageTitle__suffix: 'Talent Inc. Expert Hub',
      send: 'Send',
      preview: 'Preview',
      see__more: 'See more',
      see__less: 'See less',
      orders: 'Orders',
      topBar__account__myAccount: 'My account',
      topBar__account__logout: 'Logout',
      topBar__reviewOrders__pending: 'pending',
      topBar__reviewOrders__button: 'Review New Orders',
      account__pageTitle: 'My Account',
      account__joinedAt: 'Joined Talent Inc',
      account__contactPreferences: 'Contact Preferences',
      account__communication__title: 'Communication Preferences',
      account__communication__email_from_client: 'Email me when a client responds to an order I am working on.',
      account__communication__text_on_assignment: 'Text me when I am assigned a new order.',
      account__communication__text_on_assignment__number: 'Mobile Number',
      account__communication__email_on_assignment: 'Email me when I am assigned a new order.',
      account__communication__email_on_assignment__address: 'Email Address',
      account__payment__title: 'My Pay',
      account__payment__pending_pay: 'Pending',
      account__payment__pay_period_ends: 'Period Ends',
      account__payment__next_pay_period: 'Next Pay',
      account__payment__pay_date: 'Pay Date',
      account__payment__ytd: 'Year to Date Earnings  ',
      account__payment__past_weeks: 'Past 4 Weeks',
      account__payment__payment_date_format: 'MM/dd/yy',
      account__personal__title: 'Personal Information',
      account__personal__first_name: 'First Name',
      account__personal__last_name: 'Last Name',
      account__personal__email_address: 'Email Address',
      account__personal__secondary__email_address: 'Secondary Email Address',
      account__personal__phone: 'Mobile Number',
      account__personal__email_signature: 'Email Signature',
      account__personal__timezone: 'Timezone',
      account__error__required: 'Required',
      account__error__format: 'Invalid Format',
      account__error__email: 'Not a valid email address',
      account__save: 'Save Changes',
      account__linkedin__save: 'Save LinkedIn',
      account__linkedin__edit: 'Edit LinkedIn',
      account__saving: 'Saving',
      account__fixErrors: 'Fix Errors',
      nav__main__orders: 'Orders',
      nav__main__kb: 'KB',
      nav__main__kb_ext: 'Knowledge Base',
      nav__main__settings: 'Settings',
      nav__main__dashboard: 'Dashboard',
      nav__orders: 'My Orders',
      nav__orders__openOrders: 'Open Orders',
      nav__orders__paidOrders: 'Paid Orders',
      nav__orders__newOrders: 'New Orders',
      nav__orders__orderHistory: 'Order History',
      nav__kb: 'Knowledge Base',
      nav__kb__clientStrategy: 'Client Strategy',
      nav__kb__documentStrategy: 'Document Strategy',
      nav__kb__faqs: 'FAQs',
      nav__settings: 'Settings',
      nav__settings__account: 'Account',
      nav__settings__preferences: 'Order Preferences',
      nav__settings__contactUs: 'Contact Us',
      nav__pending: 'Pending',
      nav__claim_order_btn__label: 'Claim an Order',
      order__pendingPay: 'Pending Pay',
      order__paidYtd: 'Paid YTD',
      order__nextPayment: 'Next Payment',
      order__button__send__intro: 'Send Intro',
      order__button__send__summary: 'Send Summary',
      order__button__complete: 'Complete',
      order__button__cancelled: 'Cancelled',
      order__button__none: 'Review Order',
      order__button__refunded: 'Refunded',
      order__button__paid: 'Paid',
      order__button__reassigned: 'Client Reassigned',
      order__button__awaiting_review: 'Awaiting Review',
      order__button__awaiting_scheduling: 'Awaiting Customer',
      order__button__awaiting_confirmation: 'Awaiting Confirmation',
      order__button__upload_editor: 'Upload For Review',
      order__button__close_order: 'Close Order',
      order__button__send__message: 'Send Message',
      order__button__in_revisions: 'In Revisions',
      order__button__send__first__draft: 'Send First Draft',
      order__button__send__linkedin: 'Send LinkedIn',
      order__button__awaiting: 'Awaiting Reply',
      order__button__call: 'Complete Call',
      order__button__interview: 'Complete Interview',
      order__button__call_scheduled: 'Call Scheduled',
      order__button__schedule_call: 'Schedule Call',
      order__button__farewell: 'Farewell',
      scheduler__schedule: 'Schedule',
      order__button__schedule_interview: 'Schedule Interview',
      order__modal__call__title: 'Let Us Know About Your Call',
      order__modal__call__info:
        'Once you have arranged a date and time to speak with your client, ' +
        'let us know and we’ll make sure to remind you of your upcoming call!',
      order__modal__enterDate: 'Please Enter a Date',
      orders__tab__title__open__orders: 'My Orders',
      orders__tab__title__paid__orders: 'Paid Orders',
      orders__table__name: 'Order',
      orders__table__next__step: 'Next Step',
      orders__table__order__items: 'Order Items',
      orders__table__due: 'Due',
      orders__table__pay: 'Pay',
      orders__table__paid: 'Paid',
      orders__table__unread: 'Unread',
      orders__due_date__details_revision_ends: 'Revision Ends',
      orders__due_date__details_revision_ended: 'Revision Ended',
      orders__due_date__details_late: 'Late',
      orders__due_date__details_order_closed: 'Order Closed',
      orders__due_date__details_order_paid: 'Order Paid',
      'orders__due_date__details_view__awaiting-scheduling': 'Awaiting Customer',
      'orders__due_date__details_view__awaiting-confirmation': 'Awaiting Confirmation',
      'orders__due_date__details_view__upload-editor': 'Upload For Review by {{date}}',
      'orders__due_date__details_view__upload-editor_late': 'Upload For Review by {{date}}',
      'orders__due_date__details_view__awaiting-review': 'Revision Period Ends {{date}}',
      'orders__due_date__details_view__awaiting-review_late': 'Revision Period Ended {{date}}',
      'orders__due_date__details_view__intro-email': 'Send Introduction by {{date}}',
      // this might not ACTUALLY be reassigned yet, but from the writer's perspective it's like a "stop working on this"
      orders__due_date__details_view__cloned: 'Order Reassigned',
      'orders__due_date__details_view__first-draft': 'Send First Draft by {{date}}',
      'orders__due_date__details_view__send-message': 'Send Message by {{date}}',
      'orders__due_date__details_view__send-message_late': 'Send Remaining Documents - Send Message by {{date}}',
      'orders__due_date__details_view__send-linkedin': 'Revision Period Ends {{date}}',
      'orders__due_date__details_view__send-linkedin_late': 'Send Remaining Documents - Revision Period Ended {{date}}',
      orders__due_date__details_view__revision: 'Revision Period Ends {{date}}',
      orders__due_date__details_view__revision_late: 'Revision Period Ended {{date}}',
      orders__due_date__details_view__call: 'Call scheduled for {{date}}',
      orders__due_date__details_view__interview: 'Interview scheduled for {{date}}',
      'orders__due_date__details_view__send-summary': 'Send Summary by {{date}}',
      'orders__due_date__details_view__schedule-phone': 'Schedule Call by {{date}}',
      'orders__due_date__details_view__schedule-interview': 'Schedule Interview by {{date}}',
      orders__due_date__details_view__complete: 'Completed {{date}}',
      orders__due_date__details_view__canceled: 'Canceled {{date}}',
      orders__due_date__details_view__refunded: 'Refunded {{date}}',
      orders__due_date__details_view__paid: 'Paid {{date}}',
      orders__due_date__details_view__none: 'Due by {{date}}',
      'orders__due_date__details_view__awaiting-reply': 'Revision Period Ends {{date}}',
      'orders__due_date__details_view__awaiting-reply_late': 'Revision Period Ended {{date}}',
      'orders__due_date__details_view__close-order': 'Revision Period Ended {{date}}',
      'orders__due_date__details_view__rush-item': 'Expedited Delivery',
      orders__due_date__details_view_late: 'LATE',
      orders__sorting__new__orders: 'New Orders',
      orders__sorting__unread__messages: 'Unread Messages',
      orders__sorting__eligible__to__close: 'Eligible to Close',
      orders__sorting__revisions: 'Revisions',
      messaging__page_title: 'Messages',
      messaging__tab__title__order__details: 'Order Details',
      messaging__tab__title__message: 'Message',
      messaging__tab__title__documents: 'Documents',
      messaging__side__order__admin: 'Order #{{orderID}} {{dueDate}}',
      messaging__side__order__user: 'Order Summary',
      messaging__side__order__assigned_writer: 'Assigned Writer: {{uname}} \n Assigned On: {{assignedDate}}',
      messaging__side__orderItems: 'Order Items',
      messaging__side__paymentStatus: 'Payment Status',
      messaging__side__totalPayment: 'Total Payment',
      messaging__side__paid: 'Paid',
      messaging__side__pending: 'Pending Pay',
      messaging__side__sendFirstDraft: 'Send First Draft',
      messaging__side__toolKit: 'Tool Kit',
      messaging__side__newDocument: 'Start New Doc',
      messaging__side__template: 'Professional Templates',
      messaging__side__knowledgeShortcut: 'KB Shortcuts',
      messaging__side__documentUploads: 'Document Uploads',
      messaging__side__customerUploads: 'Customer Uploads',
      messaging__side__adminShortcuts: 'Admin Shortcuts',
      messaging__side__resume: 'Resume',
      messaging__side__coverLetter: 'Cover Letter',
      messaging__side__link__suggestedResumes: 'Suggested Resumes',
      messaging__side__link__linkedinProfile: 'Linkedin Profile',
      messaging__side__link__activeVerbs: 'Active Verbs',
      messaging__side__link__strategyScripts: 'Strategy Scripts',
      messaging__side__link__faqs: 'FAQs',
      messaging__side__link__clasicoLink: 'View Order in OMS Clasico',
      messaging__side__seeMore: 'See More',
      messaging__side__deadline: 'Deadline',
      messaging__side__nextStep: 'Next Step',
      messaging__side__notes: 'Notes',
      messaging__side__orderPay: 'Order Pay',
      messaging__side__orderContents: 'Order Contents',
      messaging__side__currentPay: 'Current Pay',
      messaging__side__orderPay__order_items: 'Order Items',
      messaging__side__orderPay__incentives: 'Incentives',
      messaging__side__orderPay__opportunities: 'Opportunities',
      messaging__side__orderPay__opportunitiesTimeCopy: 'Submit first draft by {{date}} to earn this bonus.',
      messaging__side__orderPay__opportunitiesTimeDateFormat: 'MM/dd hh:mm a',
      messaging__side__aiDraft: 'AI Draft',
      messaging__side__submit__aiFeedback: 'Submit AI Feedback',
      messaging__side__skip__aiFeedback: 'Skip AI Feedback',
      messaging__side__generate_aiDraft_btn: 'Generate AI Draft',
      messaging__side__open__resume__editor: 'Open Resume Editor',
      messaging__chat__read: 'Read',
      messaging__chat__sent: 'Sent',
      messaging__chat__subject: 'Subject: {{subject}}',
      messaging__docs__new_hire_warning__title: 'Advocate Document Check-in',
      messaging__docs__new_hire_warning__body1:
        'It is imperative that you send your initial drafts to your Writer Advocate for review before sending to your client. Please do so by clicking ‘Send to My Advocate’ below.',
      messaging__docs__new_hire_warning__body2:
        'If you have already received document approval from your Writer Advocate, click ‘Document Has Been Reviewed’ to proceed with the document upload experience.',
      messaging__docs__new_hire_warning__skip: 'Document Has Been Reviewed',
      messaging__docs__new_hire_warning__send: 'Send to My Advocate',
      messaging__docs__upload__title: 'New Client Documents',
      messaging__docs__upload__subtitle: 'Select Files to be Categorized and Reviewed for any Potential Issues',
      messaging__docs__upload__orderItems: 'Order Items',
      messaging__docs__upload__drag: 'Drag Files to Upload',
      messaging__docs__upload__typeAndSize: 'MS Word Files < 2 MB',
      messaging__docs__upload__select: 'Select Files',
      messaging__docs__upload__labelFiles: 'Label Files',
      messaging__docs__upload__agreement: 'I confirm all documents meet Talent Inc Document Standards',
      messaging__docs__upload__mentor_reviewed_agreement:
        'I confirm that my advocate has reviewed first drafts for the order.',
      messaging__docs__scan__title: 'Documents Scans',
      messaging__docs__scan__subtitle: 'Select Files to be Categorized and Reviewed for any Potential Issues',
      messaging__docs__scan__scanning: 'Document Scanning...',
      messaging__docs__scan__complete: 'Scans Complete!',
      messaging__docs__scan__foundIssue: 'Potential Customer Issue - Please Review',
      messaging__docs__scan__checkInfo: 'We check a customer’s contact information for typos and common mistakes.',
      messaging__docs__scan__removeFile: 'Remove File',
      messaging__docs__scan__correctInfo: 'Contact Info Correct',
      messaging__docs__attachments__title: 'Manage Attachements',
      messaging__docs__attachments__subtitle: 'Select Which Files You Would Like to Send to Your Customer',
      messaging__docs__btn__cancel: 'Cancel',
      messaging__docs__btn__reupload: 'Reupload',
      messaging__docs__btn__close: 'Close',
      messaging__docs__btn__skipToAttachments: 'Skip to Attachments',
      messaging__docs__btn__scan: 'Scan Files',
      messaging__docs__btn__scanning: 'Scanning Docs',
      messaging__docs__btn__review: 'Review Potential Issue',
      messaging__docs__btn__attach: 'Attach Doc',
      messaging__docs__btn__finalize: 'Finalize Upload',
      messaging__docs__btn__next: 'Next',
      messaging__docs__btn__back: 'Back',
      messaging__docs__btn__attach_plural: 'Attach {{count}} Docs',
      messaging__docs__uploadedAt: 'Uploaded: {{time}}',
      messaging__docs__uploadedAtFormat: 'MM/dd hh:mm aaaa',
      messaging__upload__scan: 'Scan',
      messaging__upload__error__fileType: 'Please select the appropriate file type for this upload.',
      messaging__upload__error__version: 'Please select the appropriate file version for this upload.',
      messaging__upload__version: 'Version',
      messaging__upload__fileType: 'File Type',
      scheduler__sessionType: 'Session Type',
      messaging__upload__dragAndDrop: 'Drag and Drop or',
      messaging__upload__browse: 'Browse',
      messaging__upload__intro: 'Clients prefer MS Word files. Make sure that your file is no more than 2mb.',
      messaging__upload__firstDraft: 'First Draft',
      messaging__upload__revision: 'Revision {{revision}}',
      messaging__scan__cancel: 'Cancel',
      messaging__scan__attach: 'Attach',
      messaging__scan__agreement__base: 'I confirm all documents meet',
      messaging__scan__agreement__qaStandards: 'QA standards',
      messaging__scan__agreement__and: 'and',
      messaging__scan__agreement__writerNetworkGuidelines: 'Writer Network Guidelines',
      messaging__cs__clientStrategy: 'Client Strategy',
      messaging__cs__selectEmailTemplate: 'Select Email Template',
      messaging__cs__clientSituation: 'Client Situation',
      messaging__cs__clientScript: 'Client Script',
      messaging__cs__insert: 'Insert',
      messaging__cs__helpLink: 'Can’t find what you are looking for? Click here to see more strategy scripts.',
      messaging__cs__script: 'Script',
      messaging__toggle_toolbar: 'Toggle Toolbar',
      messaging__toggle__client__strategy: 'Toggle Client Strategy',
      messaging__toggle__attachments: 'Toggle File Attachments',
      messaging__openedAt: 'Client Opened at {{time}}',
      messaging__message_datetime: 'MM/dd/yy h:mm a',
      messaging__to: 'To: {{emailAddress}}',
      messaging__bcc: 'BCC: <{{bccEmailAddress}}>',
      messaging__emptyState: 'Start the conversation with this client below!',
      messaging__tooltip__composeMessage: 'Compose Message',
      messaging__tooltip__collapseAllMessages: 'Collapse All Messages',
      messaging__tooltip__nextCTA: 'Perform Next CTA',
      messaging__tooltip__replyToMessage: 'Reply To Message',
      messaging__tooltip__addAttachments: 'Add Attachments',
      messaging__tooltip__openClientStrategy: 'Open Client Strategy',
      messaging__tooltip__openFormatting: 'Open Formatting',
      messaging__templates__icon__text: 'Templates',
      messaging__proposeCallTime__icon__text: 'Pick call time',
      messaging__tooltip__markRead: 'Mark As Read',
      messaging__tooltip__markUnread: 'Mark As Unread',
      messaging__noSubject: '(No Subject)',
      messaging__tool__formatting: 'Formatting',
      messaging__tool__documents: 'Documents',
      messaging__tool__templates: 'Templates',
      messaging__tool__strategy: 'Strategy',
      messaging__tool__proposeCallTime: 'Send Invite',
      messaging__error__cantDisplayMessage: "We're sorry, we can't display this message at this time.",
      order__item__done: 'Done',
      order__item__dueUrgent: 'Almost Due',
      order__item__due: 'Due',
      order__item__revisionsRequested: 'Revisions',
      order__item__late: 'Late',
      order__page__tab__title__strategy: 'Strategy',
      order__page__tab__title__digital_analysis: 'Digital Analysis',
      order__page__tab__title__client__details: 'Client Details',
      order__page__tab__title__auto_research: 'Auto Research',
      order__page__tab__title__work_history: 'Work History',
      order__page__tab__title__summary: 'Summary',
      order__page__title: 'Client #{{clientID}}',
      order__page__client__details__target__jobs: 'Target Jobs',
      order__page__client__details__goals: "{{name}}'s goals",
      order__page__client__details__client_notes: 'Client Notes',
      order__page__client__details__proudest__achievements: 'Proudest achievements',
      order__page__client__details__primary__concerns: 'Primary concerns',
      order__page__client__details__documents: 'Documents',
      order__page__client__details__due: 'Due',
      order__page__client__details__ats__report: 'ATS Report',
      order__page__client__details__critique__results: 'Critique Results',
      order__page__client__details__name: 'Name',
      order__page__client__details__industry: 'Industry',
      order__page__client__details__email: 'Email',
      order__page__client__details__address: 'Address',
      order__page__client__details__country: 'Country',
      order__page__client__details__phone: 'Phone',
      order__page__client__details__phone__type__Mobile: 'Mobile',
      order__page__client__details__phone__type__Home: 'Home',
      order__page__client__details__phone__type__Work: 'Work',
      order__page__client__details__source: 'Source',
      order__page__client__details__linkedin: 'LinkedIn Profile',
      order__page__jobcard__jobTarget: 'Job Target',
      order__page__jobcard__alt__text: "{{host}}'s icon",
      order__page__summary__internationial: 'International',
      order__page__work_history__positions: 'Positions',
      order__page__work_history__description: 'All the information you’ll need from your client’s work history',
      order__page__work_history__select_a_role: 'Select a role',
      order__page__work_history__to_view_a_role: 'to view details',
      order__page__work_history__suggested_data: 'Suggested Data',
      order__page__work_history__suggested_data_tooltip:
        'These are samples from related resumes that might work well for this client. Take care to revise and customize these suggestions for this client.',
      order__page__work_history__soft_skills: 'Soft Skills',
      order__page__work_history__hard_skills: 'Hard Skills',
      order__page__work_history__job_duty_recommendations: 'Job Duty Recommendations',
      order__page__work_history__client_provided_info: "From Client's Resume",
      order__page__work_history__onboarding_questionnaire: 'Proudest Achievements',
      order__page__work_history__has_no_data: 'Please refer to resume for details.',
      order__page__summaryTab__summary__desciption:
        'Quickly and accurately review relevant information to include in your client’s new resume.',
      order__page__summaryTab__suggested__data: 'Suggested Data',
      order__page__summaryTab__skill__and__keywords: 'Skill and Keywords',
      order__page__summaryTab__essential__keywords__clients__resume:
        'Essential keywords and phrases to include in your client’s new resume.',
      order__page__summaryTab__soft_skills: 'Soft Skills:',
      order__page__summaryTab__hard_skills: 'Hard Skills:',
      order__page__summaryTab__supplied__by__client: 'Supplied by Client',
      order__page__summaryTab__has_no_data: 'Please refer to resume for details.',
      order__page__summaryTab__summary__tooltip:
        'These are samples from related resumes that might work well for this client. Take care to revise and customize these suggestions for this client.',
      order__page__summaryTab__skills__tooltip:
        'These are samples of possible keywords based on this client’s goals. Take care to select only those that relate to the client’s experience.',
      order_page_download_client_info_btn: 'Download Client Info',
      order_page_download_client_pdf_client_overview: 'Client Overview',
      order_page_download_client_pdf_linkedIn: 'LinkedIn',
      order_page_download_client_pdf_suggested_job_recommendations: 'Suggested Job Duty Recommendations',
      documents__uploads: 'Uploads',
      documents__customerUploads: 'Customer Uploads',
      documents__editorUploads: 'Editor Uploads',
      documents__uploadDocuments: 'Upload Documents',
      // @TODO Remove, replace usages
      documents_sent: 'Sent',
      documents_received: 'Received',
      documents__sent: 'Sent',
      documents__received: 'Received',
      scan__operations__ats_results: 'ATS Results',
      scan__operations__virus: 'Virus',
      scan__operations__virus_details:
        'This file appears to have a virus, please reach out to writer support for further assistance.',
      scan__operations__clearing_props: 'Clearing Properties',
      scan__operations__draft_comparison: 'Contact Information',
      scan__operations__auto_qa: 'Auto QA',
      scan__operations__file_size: 'File Size',
      scan__operations__clearing_props_details: 'Could not watermark file.',
      scan__operations__file_size_details:
        'This document is pretty big ({{file_size}}). Consider decreasing its size in future revisions.',
      scan__operations__draft_comparison_output_item: 'Item',
      scan__operations__draft_comparison_output_original: 'Original',
      scan__operations__draft_comparison_output_draft: 'Your draft',
      scan__operations__draft_comparison_output_first_name: 'First Name',
      scan__operations__draft_comparison_output_last_name: 'Last Name',
      scan__operations__draft_comparison_output_name: 'Name',
      scan__operations__draft_comparison_output_email: 'Email',
      scan__mistakesHappen: 'Mistakes happen to the best of us.  Just make sure everything looks good to you!',
      revision_0: 'First Draft',
      revision_n: 'Revision {{revision}}',
      onboarding__back: 'Back',
      onboarding__getStarted: 'Get Started',
      onboarding__setMySchedule: 'Set My Schedule',
      onboarding__setMyIndustries: 'Set My Industries',
      onboarding__addPaymentInfo: 'Add Payment Info',
      onboarding__completeProfileSetup: 'Complete Profile Setup',
      onboarding__welcome: 'Welcome to the Talent Inc. OMS!',
      onboarding__welcome__systemDescription:
        'You will use this order management system for everything you will do as an Talent Inc Expert. ' +
        'From here you will review new orders, upload your work, review best practices ' +
        'and communicate with your client!',
      onboarding__welcome__processDescription:
        'Before you get to work, we have a few quick questions about your scheduling preferences and then we will ' +
        'introduce you to your Writer Advocate!',
      onboarding__shareGoals: 'Share Your Productivity Goals',
      onboarding__workloadActivity:
        'With a steady stream of clients seeking help with their professional documents, ' +
        "we're ready to keep you as busy as you'd like to be.",
      onboarding__systemActivity:
        'You will use this order management system for everything you will do as an Talent Inc Expert.',
      onboarding__workWheelhouse: 'Work in Your Wheelhouse',
      onboarding__clientsActivity: "We'll match you with clients whose backgrounds and career goals you understand.",
      onboarding__setupBilling: 'Set Up Your Billing Information',
      onboarding__billingInfoNotice:
        'Writer payments are sent weekly. Please provide your relevant billing information below.',
      onboarding__availability__question:
        "On average, how much availability do you think you'll have to work with us each week?",
      onboarding__paypal_copy:
        'We are excited to support 0 fee transactions with PayPal!\n Please ensure the email address for your PayPal account matches the address that was used to set up your Talent Inc account.\n Click <a href="mailto:payment@talentinc.com">here</a>  if you have any questions or concerns about our PayPal payments.',
      onboarding__availability__moreThan: 'More than',
      onboarding__availability__between: 'Between',
      onboarding__availability__lessThan: 'Less than',
      onboarding__availability__hours: 'hours',
      onboarding__days__question: 'Which days of the week did you prefer to take orders on?',
      onboarding__days__questionClarify: 'You may update your selections at any time inside your dashboard.',
      onboarding__days__day1: 'SUN',
      onboarding__days__day2: 'MON',
      onboarding__days__day3: 'TUE',
      onboarding__days__day4: 'WED',
      onboarding__days__day5: 'THU',
      onboarding__days__day6: 'FRI',
      onboarding__days__day7: 'SAT',
      onboarding__domains__question: 'Which 3 industries would you feel most comfortable writing documents for first? ',
      onboarding__domains__questionClarify:
        'You will have full access to the entire range of industry options after completing your first few orders.',
      onboarding__domains__select: 'Select three industries',
      industry__industry1: 'General Business',
      industry__industryDescription1: 'Sales, Marketing, Accounting, Administrative Services',
      industry__industry2: 'Professional Services',
      industry__industryDescription2: 'Legal, HR, Consulting, Law Enforcement',
      industry__industry3: 'Academic',
      industry__industryDescription3: 'Teacher, Professor, Administration',
      industry__industry4: 'Skilled Trades',
      industry__industryDescription4: 'Construction, Manufacturing, Facilities, Personal Care',
      industry__industry5: 'Healthcare',
      industry__industryDescription5: 'Doctor, Pharma, Nurse, Administration',
      industry__industry6: 'Science and Engineering',
      industry__industryDescription6: 'Research, Oil/Gas, Product Development',
      industry__industry7: 'Technical',
      industry__industryDescription7: 'IT, Software Development, Telecom, Networking',
      industry__industry8: 'Arts & Design',
      industry__industryDescription8: 'UX, Graphic Design, Visual Arts, Entertainment',
      industry__industry9: 'Financial Services',
      industry__industryDescription9: 'Banking, Lending, Investing, Real Estate',
      industry__industry10: 'Writing & Editing',
      industry__industryDescription10: 'Communications, Editor, Journalism',
      industry__industry11: 'Arts',
      industry__industryDescription11: 'Actor, Musician, Writer',
      industry__industry12: 'Other',
      industry__industryDescription12: '',
      industry__industry13: 'Government',
      industry__industryDescription13: 'Federal, State, Local, Agencies, Contractors',
      industry__industry14: 'Construction + Manufacturing',
      industry__industryDescription14: 'Project Development, Project Management',
      industry__industry15: 'Logistics + Transportation',
      industry__industryDescription15: 'Supply Chain, Distrubution, Aviation',
      industry__industry16: 'Engineering',
      industry__industryDescription16: 'Mechanical, Industrial, Civil',
      industry__industry17: 'Science',
      industry__industryDescription17: 'Research, Biotech, Chemical',
      industry__industry18: 'Natural Resources',
      industry__industryDescription18: 'Oil/Gas, Mining, Renewables',
      industry__industry19: 'Information Technology',
      industry__industryDescription19: 'Network Security, Telecommunications, Cloud Computing',
      industry__industry20: 'Software',
      industry__industryDescription20: 'Development, Analysis, DevOps, QA',
      onboarding__allSet: 'All Set. You are Good to Go',
      onboarding__checkInbox: 'Next up, check your inbox for:',
      onboarding__checkInbox__anIntroduction: 'An introduction from your Writer Advocate',
      onboarding__checkInbox__payInstructionsPayPal:
        'An email from our finance team regarding setting up your PayPal account',
      onboarding__checkInbox__payInstructionsBill: 'Instructions to finalize your payment information with Bill.com',
      order__shopping__request__new__order: 'Request a New Order',
      order__shopping__tab__title__new__order: 'New Order',
      order__shopping__pageTitle: 'Order Shopping',
      order__shopping__tab__title__preferences: 'Preferences',
      order__shopping__target__roles: 'Target Roles',
      order__shopping__draft__due: 'Draft due',
      order__shopping__pay: 'Pay',
      order__shopping__accept: 'Accept',
      order__shopping__reject: 'Reject',
      order__shopping__reject__title: 'No problem. Could you tell us why?',
      order__shopping__reject__intro:
        'We’re making an effort to better understand order types so that in the future we can present you with opportunities that best fit your needs.',
      order__shopping__reject__feedback__question: 'What about this order isn’t a good fit for you?',
      order__shopping__reject__feedback__option__industry: 'The Industry',
      order__shopping__reject__feedback__option__items: 'The items in the order',
      order__shopping__reject__feedback__option__due__date: 'The due date',
      order__shopping__reject__feedback__option__region: 'The region',
      order__shopping__reject__feedback__option__unavailability: 'I’m unavailable',
      order__shopping__reject__feedback__textarea: 'Any other reasons this order isn’t a good fit?',
      order__shopping__reject__feedback__submit: 'Submit',
      order__shopping__reject__feedback__close: 'Close',
      order__shopping__default__headline: 'Click below to review new orders in our system',
      order__shopping__no_offers__headline: 'Please come back later',
      order__shopping__default__bodytext:
        'We have a number of customers waiting to work with someone like you. Review your options and accept work that matches your expertise!',
      order__shopping__no_offers__bodytext:
        "We don't have any orders that match your expertise and preferences at the moment. Please check back in a bit.",
      order__shopping__offer__accepted__headline: 'Order Accepted',
      order__shopping__offer__incentive_eligible: 'Eligible',
      order__shopping__offer__incentive_applied: 'Applied',
      order__shopping__offer__accepted__bodytext:
        'Great work! Your customer is excited to work with you. Reach out to introduce yourself as soon as you can.',
      order__shopping__offer__accepted__review__new__order: 'Review New Order',
      order__shopping__offer__accepted__review__more__offers: 'More offers',
      order__shopping__offer__rejections__limit__headline: 'Offer Rejections Limit Reached',
      order__shopping__offer__rejections__limit__bodyText:
        'In order to ensure everyone in our network gets a fair crack at new orders in our system, we limit the number of times you can reject new offers. Please try again in an hour. ',
      order__shopping__offer__rejections__limit__review__assigned__work: 'Review Assigned Work',
      order__shopping__offer__stated_capacity_reached__headline: 'Order Limit Reached',
      order__shopping__offer__stated_capacity_reached__bodyText:
        'You have already accepted the maximum number of orders that you would like to work on at any given time per order preferences. Please submit a first draft for an order in your queue or edit your preferences.',
      order__shopping__offer__stated_capacity_reached__review_prefs: 'Review Preferences',
      order__shopping__offer__stated_capacity_reached__review_assigned_work: 'Review Assigned Work',
      order__shopping__offer__new_hire_capacity_reached__bodyText:
        'For writers new to the Talent Inc network, we limit how many orders you can work on at a given time. Please submit drafts to orders already in your queue before accepting another order. Once you have submitted ten drafts of acceptable quality to your customers, you will gain the ability to take on more projects at any given time.',
      order__shopping__offer__expired__headline: 'Offer Expired',
      order__shopping__offer__expired__bodytext:
        'Each offer is available to you for five minutes, after which it will be presented to someone else who might be interested in taking it.',
      order__shopping__wip_limit_reached__headline: 'Order Limit Reached',
      order__shopping__wip_limit_reached__bodytext:
        'You have {{ n }} new orders in your queue. Please submit a draft for one of them before accepting a new order.',
      order__shopping__low_inventory__headline: 'No Orders Available',
      order__shopping__low_inventory__bodytext:
        'We don’t have any orders in our system that currently match your preferences and expertise. Please check back in a bit.',
      order__shopping__suspended__headline: 'Suspension Active',
      order__shopping__suspended__bodytext:
        'You have been suspended from accepting new orders. Please contact us if you feel there has been a mistake.',
      order__shopping__offer__expired__close: 'Close',
      order__shopping__feedback_recorded__headline: 'Thank you for your feedback!',
      order__shopping__feedback_recorded__review_more_offers: 'More Offers',
      order__shopping__feedback_recorded__review_assigned_work: 'Review Assigned Work',
      order__shopping__offer__bodytext: 'Review Assigned Work',
      order__shopping__stats__accepted__this__week: 'Accepted this week',
      order__shopping__stats__hourly__rejections__remaining: 'Hourly Rejections Remaining:',
      order__shopping__stats__accepted__last_week: '{{offersAcceptedLastWeek}} of {{offersPerWeek}} Orders',
      order__shopping__stats__wip: '{{wipCount}} of {{wipMax}} Orders',
      order__shopping__stats__wip__subtitle: 'In Progress',
      order__shopping__stats__time__remaining: 'Time Remaining',
      order__shopping__preferences__capacity__title: 'Weekly Order Capacity',
      order__shopping__preferences__capacity__desc: 'The orders per week I am available for:',
      order__shopping__preferences__capacity__orders: '{{count}} Order',
      order__shopping__preferences__capacity__orders_plural_0: '{{count}} Orders - Do not assign me any more orders',
      order__shopping__preferences__capacity__orders_plural: '{{count}} Orders',
      order__shopping__preferences__capacity__none: 'Do not assign me any more orders',
      order__shopping__preferences__availability__title: 'Weekly Availability',
      order__shopping__preferences__availability__desc:
        'I am available to accept orders on the following days of the week:',
      order__shopping__preferences__speed__title: 'Delivery Speed',
      order__shopping__preferences__speed__desc: 'The delivery speed(s) I prefer to take is:',
      order__shopping__preferences__speed__standard: 'Standard',
      order__shopping__preferences__speed__rush: 'Rush Delivery',
      order__shopping__preferences__phone__title: 'Phone Calls',
      order__shopping__preferences__phone__desc: 'I am available to take phone calls',
      order__shopping__preferences__industries__title: 'Industries',
      order__shopping__preferences__industries__desc: 'I prefer these industries below from most to least:',
      order__shopping__preferences__help:
        'While we cannot guarantee you will only be assigned projects for your preferences, we will do our best ' +
        'to match you accordingly. See the Knowledge Base for more info. ',
      order__shopping__preferences__submit: 'Update Order Preferences',
      resume__preview__alt__text: 'Resume Preview',
      resume__preview__page__count: '{{count}} Page',
      resume__preview__page__count_plural: '{{count}} Pages',
      resume__preview__download: 'Download',
      digital__analysis__title__industry: 'Industry, with Confidence',
      digital__analysis__title__keywords: 'Top Keywords & Skills',
      digital__analysis__title__formatting: 'Formatting',
      digital__analysis__title__content: 'Content',
      digital__analysis__industry__confidence__format: '{{industry}} ({{percent}}%)',
      digital__analysis__critique__employment_gaps: 'Employment gaps not addressed',
      digital__analysis__critique__file_size_too_long: 'Resume too long',
      digital__analysis__critique__file_size_too_small: 'Resume not long enough',
      digital__analysis__critique__has_both_summary_and_objective: 'Weak career summary and outdated objective',
      digital__analysis__critique__has_monotonous_words: 'Monotonous language',
      digital__analysis__critique__has_no_objective_and_no_summary: 'Missing career summary',
      digital__analysis__critique__has_references: 'References listed',
      digital__analysis__critique__is_doer:
        'Insufficient focus on contributions/accomplishments (request specifics from client).',
      digital__analysis__critique__less_than_three_years: 'Unpolished for junior level',
      digital__analysis__critique__three_or_more_years: 'Unpolished for senior level',
      digital__analysis__critique__bullet_points_too_few: 'Too few bullets',
      digital__analysis__critique__bullet_points_too_many: 'Too many bullets',
      digital__analysis__critique__too_many_pages: 'Too many pages',
      digital__analysis__critique__too_many_words: 'Too many words',
      message__input__subject: 'Subject:',
      message__input__placeholder: 'Email somebody…',
      message__input__placeholderWarning: 'Placeholder text must be deleted before sending!',
      document__item__drag__me: 'Drag me to the message input to attach me to the message!',
      order_item__state__first_draft_due: 'Send First Draft',
      order_item__state__first_draft_late: 'First Draft Late',
      order_item__state__first_draft_done: 'First Draft Sent',
      order_item__state__first_draft_resume_edit_due: 'Send Final Draft',
      order_item__state__first_draft_resume_edit_late: 'Final Draft Late',
      order_item__state__first_draft_resume_edit_done: 'Final Draft Sent',
      order_item__state__revision_due: 'Revision Ends',
      order_item__state__revision_late: 'Revision Ended',
      order_item__state__client_reassigned: 'Client Reassigned',
      order_item__state__client_reassigned_done: 'Client Reassigned',
      order_item__state__revision_done: 'Revision Ended',
      order_item__state__send_intro_due: 'Send Intro',
      order_item__state__send_intro_done: 'Intro Sent',
      order_item__state__send_intro_late: 'Send Intro',
      order_item__added_on: 'Added on {{ date }}',
      order_item__state__closed: 'Completed',
      order_item__state__closed_done: 'Completed',
      order_item__state__upload_editor: 'Upload For Review',
      order_item__state__upload_editor_late: 'Upload For Review Late',
      order_item__state__upload_editor_done: 'Uploaded For Review',
      order_item__state__upload_editor_due: 'Upload For Review',
      order_item__state__awaiting_reply: 'Awaiting Reply',
      order_item__state__awaiting_review: 'Awaiting Review',
      order_item__state__awaiting_review_done: 'Reviewed',
      order_item__state__awaiting_reply_due: 'Awaiting Reply',
      order_item__state__awaiting_resume_due: 'LinkedIn Due',
      order_item__state__awaiting_resume: 'Due After Other Documents',
      order_item__state__awaiting_resume_late: 'Due After Other Documents',
      order_item__state__awaiting_resume_done: 'Due After Other Documents',
      order_item__state__send_message: 'Send Message',
      order_item__state__send_message_due: 'Send Message',
      order_item__state__send_message_late: 'Send Message Late',
      order_item__state__call_due: 'Call',
      order_item__state__call_late: 'Call Late',
      order_item__state__call_done: 'Completed call',
      order_item__state__interview_due: 'Interview',
      order_item__state__interview_late: 'Interview Late',
      order_item__state__interview_done: 'Completed interview',
      order_item__state__send_summary_due: 'Send Summary',
      order_item__state__send_summary_late: 'Send Summary Late',
      order_item__state__send_summary_done: 'Sent Summary',
      order_item__state__schedule_phone_call: 'Schedule Phone Call',
      order_item__state__schedule_phone_call_due: 'Schedule Phone Call',
      order_item__state__schedule_phone_call_late: 'Schedule Phone Call Late',
      order_item__state__schedule_phone_call_done: 'Scheduled Phone Call',
      order_item__state__schedule_interview: 'Schedule Interview',
      order_item__state__schedule_interview_due: 'Schedule Interview',
      order_item__state__schedule_interview_late: 'Schedule Interview Late',
      order_item__state__schedule_interview_done: 'Scheduled Interview',
      order_item__state__canceled: 'Canceled',
      order_item__state__canceled_done: 'Canceled',
      order_item__state__refunded: 'Refunded',
      order_item__state__paid: 'Paid',
      order_item__state__send_linkedin: 'Send Linkedin',
      order_item__state__send_linkedin_due: 'Send Linkedin',
      order_item__state__send_linkedin_late: 'Send Linkedin Late',
      order_item__state__send_linkedin_done: 'Sent Linkedin',
      order_item__state__awaiting_scheduling: 'Awaiting Customer',
      order_item__state__awaiting_scheduling_due: 'Awaiting Customer',
      order_item__state__awaiting_scheduling_late: 'Awaiting Customer',
      order_item__state__awaiting_scheduling_done: 'Call Scheduled',
      order_item__state__awaiting_confirmation: 'Awaiting Confirmation',
      order_item__state__awaiting_confirmation_due: 'Awaiting Confirmation',
      order_item__state__awaiting_confirmation_late: 'Awaiting Confirmation',
      order_item__state__awaiting_confirmation_done: 'Customer Confirmed Time',
      order_item__state__paid_done: 'Paid',
      order_item__state__refunded_done: 'Refunded',
      accomplishmentList__defaultText: 'No accomplishments provided',
      notification__promise__text: 'We have new orders in our system that match your preferences!',
      notification__error__text: 'Oh no! This page failed to load.',
      notification__error__submitBug: 'Submit a Bug Report ',
      notification__error__portal: 'OMS 1.0',
      notification__error__messageSend: 'Oh no! Could not send this message.',
      notification__error__messageSend_exceptions: "We don't accept phrase system(), please edit your message.",
      notifications__mark__all__as__read: 'Mark all as read',
      notifications__headline: 'Notifications',
      notifications__ago: '{{date}} ago',
      notification__upgradeApp:
        'New version of the application is available. Please refresh the page or click here to do so.',
      client__strategy__snippets: 'Client Strategy Snippets',
      client__strategy__snippetsInsert: 'Insert',
      clients_list__filtering__open: 'All Orders',
      clients_list__filtering__new: 'New Orders',
      clients_list__filtering__unread_messages: 'Unread Messages',
      clients_list__filtering__eligible_close: 'Eligible to Close',
      clients_list__filtering__revisions: 'Revisions',
      clients_list__filtering__toSchedule: 'To Schedule',
      clients_list__filtering__scheduled: 'Scheduled',
      clients_list__filtering__sendSummary: 'Send Summary',
      clients_list__filtering__phoneCalls: 'Phone Calls',
      clients_list__filtering__complete: 'Complete',
      emailTemplate__replyAboveThisLine: 'Please Reply To Your Writer Above This Line',
      emailTemplate__reviewYourOrder: 'Review Your Order',
      emailTemplate__reply: 'Reply',
      emailTemplate__yourFiles: 'Your Files',
      auth__callbackErrorTitle: "Uh-oh! We're having a little bit of trouble logging you in.",
      auth__callbackErrorFromProvider: 'Error from our provider: {{error}}',
      auth__callbackErrorGeneric:
        'user was able to login, but there was an error in the result (authStatus = {{authStatus}})',
      notes__dateFormat: 'LL/dd/y',
      notes__list__newNote: 'New Note',
      notes__nameClientID: '{{name}} (#{{clientID}})',
      notes__completedOn: 'Completed on {{date}} at {{time}}',
      notes__save: 'Save',
      notes__delete: 'Delete',
      notes__confirmDelete: "Yes, I'm sure",
      notes__markAsDone: 'Mark As Done',
      notes__newNoteForClient: '{{name}} (#{{clientID}})',
      notes__existingNotesForClient: '{{name}} (#{{clientID}})',
      notes__section__all: 'All',
      notes__section__dueSoon: 'Due Soon',
      notes__section__myNotes: 'My Notes',
      notes__section__completed: 'Completed',
      notes__dueOn: 'Due on:',
      notes__completedOnLabel: 'Completed on',
      notes__dueInDay: 'Due in {{distance}} on',
      notes__dueDaysAgo: 'Due {{distance}} ago',
      notes__dueToday: 'Due today on',
      notes__label__at: 'at',
      notes__titleOrder: 'Title/Order:',
      notes__errors__expirationDateMustBePresent: 'Must have a date.',
      notes__errors__expirationDateTooFarAway: 'Cannot be more than a month in the past.',
      dashboard__pageTitle: 'Dashboard',
      dashboard__earningsProgress_title: 'Earnings Progress',
      dashboard__new_hire_bonus_progress__title: '$250 Bonus Progress',
      dashboard__new_hire_bonus_progress__title__low_cost: '$100 Bonus Progress',
      dashboard__moneyProgress_of: 'of {{value}}',
      dashboard__moneyPerWeek: 'per week',
      dashboard__goalSliderTitle: 'Edit Weekly Goal',
      dashboard__macro__title: 'You have...',
      dashboard__macro__messagecount__linkText: '{{messageCount}} unread messages',
      dashboard__macro__messagecount__bodyCopy: 'from {{clientCount}} clients',
      dashboard__macro__overdue: '{{taskCount}} tasks past due',
      dashboard__macro__taskcount: '{{taskCount}} tasks due {{due_time_period}}',
      dashboard__macro__ordercloseclause__linkText: '{{orderCloseCount}} orders eligible to close',
      dashboard__macro__ordercloseclause__bodyText: 'this week',
      dashboard__macro__phonecallsclause__linkText: '{{phoneCallCount}} phone calls',
      dashboard__macro__phonecallclause__linkText: '{{phoneCallCount}} phone call',
      phone_calls_due_today: 'today',
      phone_calls_due_tomorrow: 'tomorrow',
      phone_calls_due_thisweek: 'this week',
      dashboard__macro__emptycard: 'No upcoming tasks',
      dashboard__macro__mentorwelcome: "We are so happy you're here!",
      dashboard__macro__mentor: 'To learn the ropes, continue working with {{mentorName}} on your first few orders.',
      dashboard__macro__start: "Let's get started",
      dashboard__orderProgress__title: 'Order Progress',
      dashboard__orderProgress__primaryText: '{{orders}} orders',
      dashboard__orderProgress__secondaryText: 'closed out of {{orders}}',
      dashboard__draftsProgress__primaryText: '{{drafts}} drafts',
      dashboard__getNewOrdersButton__title: 'Get New Orders',
      dashboard__draftsProgress__infoText:
        'Get a bonus of $250 when you complete 10 high-quality drafts within your first month.',
      dashboard__draftsProgress__secondaryText: 'done out of {{drafts_goal}}',
      dashboard__daysVariant__primaryText: '{{days, fractionalDays}} days',
      dashboard__daysVariant__notEnoughData: 'Not enough data',
      dashboard__avgFirstDraft__title: 'AVG Time To First Draft (Last 30 Days)',
      dashboard__avgResponseTime__title: 'AVG Response Time (All Time)',
      dashboard__relative__output__high: 'Your output is higher than last month',
      dashboard__relative__output__low: 'Your output is lower than last month',
      dashboard__relative__output__avg: 'Your output is steady, you can do this!',
      dashboard__relative__output__none: 'We are here to help you find success',
      dashboard__relative__output__more: 'At this rate, you will earn more compared to the last month',
      dashboard__relative__output__less: 'At this rate, you will earn less compared to the last month',
      dashboard__relative__output__same: 'At this rate, you will earn roughly the same compared to the last month',
      dashboard__relative__output__child__none:
        'Click the support icon on the bottom right to explore helpful articles and speak to our team',
      dashboard__industriesChart__title: 'Top 5 Industries Written For',
      dashboard__industriesChart__toolTip: '{{count}} resumes written',
      dashboard__overallPerformance__title: 'Overall Performance',
      dashboard__ytdChart__week: 'Week {{weekNumber}}',
      dashboard__earningsChart__base: 'Base',
      dashboard__earningsChart__bonus: 'Bonus',
      tasksdue__urgency__soon: 'in 48 hours',
      tasksdue__urgency__urgent: 'in 24 hours',
      tasksdue__urgency__week: 'this week',
      tasksdue__urgency__late: 'past due',
      dashboard__payhist__title: 'Payment History',
      dashboard__payhist__header__date: 'Date',
      dashboard__payhist__header__items: 'Order Items',
      dashboard__payhist__header__amount: 'Amount',
      dashboard__payhist__orders: '{{count}} order',
      dashboard__payhist__orders_plural: '{{count}} orders',
      dashboard__payhist__bonus__orders: '{{count}} bonus order',
      dashboard__payhist__bonus__orders_plural: '{{count}} bonus orders',
      dashboard__payhist__last_pay_period: 'Last Pay Period',
      dashboard__payhist__header__details: 'Details',
      dashboard__payhist__all_time: 'All Time',
      dashboard__paycard__last: 'Last Pay Amount',
      dashboard__paycard__next: 'Next Pay Amount',
      dashboard__paycard__nextpaydate: 'in {{daysTill}} days on {{payDate}}',
      dashboard__paycard__nextpaydateToday: 'Today',
      dashboard__paycard__nextpaydateTomorrow: 'Tomorrow',
      dashboard__earningsChart__title: 'Year To Date Earnings ({{year}})',
      dashboard__earningsChart__changeDisplaySubtitle: 'Last YTD',
      dashboard__earningsChart__sinceStarted: 'Since Started',
      dashboard__unreadMessages__title: 'Unread Messages',
      dashboard__unreadMessages__emptytitle: "You're all caught up!",
      dashboard__unreadMessages__emptysubtitle: "We'll notify you if there are any new unread messages",
      dashboard__unreadMessages__row: '<b>{{msg_count}} Messages</b> from <b>{{client_name}} (#{{clientID}})</b>',
      dashboard__recent_activity__title: 'Recent Activity',
      dashboard__recent_activity__empty_title: 'Nothing to see here',
      dashboard__recent_activity__empty_wording: 'When something happens, we will let you know',
      dashboard__recent_activity__last_refreshed_now: 'last refreshed just now',
      dashboard__recent_activity__last_refreshed_min: 'last refreshed < 1m ago',
      dashboard__recent_activity__last_refreshed_min_ago: 'last Refreshed  {{ minute }}m ago',
      [`dashboard__greeting__${DashboardVariants.ActiveIC}`]: 'Welcome back, {{name}}!  👋',
      [`dashboard__greeting__${DashboardVariants.InactiveIC}`]: 'Nice to see you again, {{name}}!  👋',
      [`dashboard__greeting__${DashboardVariants.Offshore}`]: 'Welcome back, {{name}}!  👋',
      [`dashboard__greeting__${DashboardVariants.Mentee}`]: 'Welcome to the network, {{name}}! 🌱',
      [`dashboard__greeting__${DashboardVariants.NewHire}`]: 'Good {{timeOfDay}}, {{name}}! 👋',
      dashboard__greeting__timeOfDay__morning: 'morning',
      dashboard__greeting__timeOfDay__afternoon: 'afternoon',
      dashboard__greeting__timeOfDay__evening: 'evening',
      events__firstDraft__wording_single:
        ' <b>First Draft</b> sent to <b> {{ client_name }} <a href="{{ client_link }}">(#{{ client_id}} )</a></b>',
      events__firstDraft__wording_multiple:
        '<b>{{ doc_keys }} First Drafts </b> sent to <b> {{ client_name }} <a href="{{ client_link }}">(#{{ client_id}})</a></b>',
      events__revision__wording:
        '<b>{{ docs }}</b> sent to <b>{{ client_name }}  <a href="{{ client_link }}">(#{{client_id}})</a></b>',
      events__order_closed_client__wording:
        '<b>{{ client_name }}</b> closed order <b><a href="{{ client_link }}">(#{{client_id}})</a></b>',
      events__order_closed__wording:
        '<b>{{ client_name }}</b> closed <b><a href="{{ client_link }}">(#{{client_id}} )</a></b>',
      events__order_refunded_paid__wording:
        '<b>{{ client_name }} <a href="{{ client_link }}">(#{{client_id}})</a> </b> refunded. You will be paid for this order.',
      events__order_refunded_not_paid__wording:
        '<b>{{ client_name }} <a href="{{ client_link }}">(#{{client_id}})</a> </b> refunded. You will not be paid for this order.',
      events__order_canceled__wording:
        '<b>{{ client_name }} <a href="{{ client_link }}">(#{{client_id}})</a> </b> canceled.',
      events__order_bonus_created__wording:
        '<b>{{ client_name }} <a href="{{ client_link }}">(#{{client_id}})</a></b> gave you a  {{ rating }} &#9733 bonus! Great job!',
      events__order_adhoc_added__wording:
        // eslint-disable-next-line
        'You got ad-hoc payment of <b>${{ amount }}</b> for <b>{{ client_name }} <a href="{{ client_link }}">(#{{ client_id}})</a></b> ',
      events__orders_paid__wording: 'You have been paid {{ payment }}.',
      events__earned_performance_incentive__wording:
        'You have earned performance incentive for <b>{{ client_name }} <a href="{{ client_link }}">(#{{client_id}})</a> </b>.',
      events__order_item_added__wording:
        '<b>{{ client_name }} <a href="{{ client_link }}">(#{{client_id}})</a> </b> {{ items }} added.',
      events__order_item_deleted__wording:
        '<b>{{ client_name }} <a href="{{ client_link }}">(#{{client_id}})</a> </b> {{ items }} deleted.',
      events__order_reassigned__wording:
        '<b>{{ client_name }} <a href="{{ client_link }}">(#{{ client_id}})</a> </b> was reassigned from you.',
      events__default__wording:
        '<b>{{ client_name }} <a href="{{ client_link }}">(#{{ client_id}})</a> </b> : {{ event }}',
      doc_key__resume: 'Resume',
      'doc_key__cover-letter': 'Cover Letter',
      'doc_key__linkedin-document': 'Linkedin',
      doc_key__cv: 'Curriculum Vitae',
      'doc_key__additional-document|thank-you-letter': 'Thank You Letter',
      'doc_key__additional-document|follow-up-letter': 'Follow-Up Letter',
      'doc_key__additional-document|ksa': 'KSA Document',
      'doc_key__additional-document|references-page': 'References Page',
      'doc_key__additional-document|resignation-letter': 'Resignation Letter',
      'doc_key__additional-document|decline-letter': 'Offer Decline Letter',
      'doc_key__additional-document|acceptance-letter': 'Offer Acceptance Letter',
      infiniteList__loading: 'Loading…',
      projectedEarnings__text: 'Accepted',
      earnings__dateFormat: 'MM/dd/yyyy',
      earnings__topNav__title: 'Earnings Progress',
      earnings__topNav__legend_earned: 'Earned: {{ amount }}',
      earnings__topNav__legend_accepted: 'Accepted: {{ amount }}',
      earnings__topNav__legend_goal: 'Goal: {{ amount }}',
      earnings__topNav__subtitle: 'ends {{end_date}}',
      templateLinks__suggestedTemplate: 'Suggested Template',
      searchInput__placeholder: 'Search',
      uploadsv3__uploads__title: 'New Document Upload',
      uploadsv3__scan__scanningTitle: 'Hang tight...',
      uploadsv3__scan__blockingTitle: 'Few things before we proceed...',
      uploadsv3__scan__totalAlerts: 'Total Alerts: {{count}}',
      uploadsv3__improvements__title: "Let's improve this document and reupload it",
      uploadsv3__atttachments__title: 'Select which files you’d like to send',
      uploadsv3__scanItem__improvementsSectionTitle: 'Notable Improvements',
      uploadsv3__scanItem__sectionAssesmentTitle: 'Section Assessment',
      uploadsv3__scanItem__scanningDetailDefault: 'Checking for viruses, tracked changes and other irregularities',
      uploadsv3__scanItem__scanningDetailDraftComparison: 'Document content analysis…',
      uploadsv3__scanItem__pass_no_results: 'Hey! This document looks pretty good!',
      uploadsv3__scanItem__pass_has_results: 'Great work! We made a few notes for you to review.',
      uploadsv3__scanItem__warn: 'This looks good but there are a few things you should review before sending',
      uploadsv3__scanItem__fail: 'We have concerns about this document',
      uploadsv3__scanItem__contactInformation: 'Contact Information',
      uploadsv3__scanItem__verbatim: 'Originality',
      uploadsv3__autoqa__improvements__verbatim: 'Enhanced Originality',
      uploadsv3__autoqa__improvements__contact_info: 'Corrected Client Info',
      uploadsv3__autoqa__improvements__templates: 'Reduced templates usage',
      uploadsv3__autoqa__improvements__weak: 'Improved Word Choice',
      uploadsv3__autoqa__improvements__personal: 'Grammar Improvements',
      uploadsv3__autoqa__improvements__repetition: 'Enhanced Readability',
      uploadsv3__scanItem__wordChoice: 'Readability',
      uploadsv3__scanItem__uploading: 'uploading…',
      uploadsv3__scanItem__giant_review_button__pass_has_results: 'Click to expand suggestions',
      uploadsv3__scanItem__giant_review_button__fail:
        'Please review our feedback and make any needed changes before sending to your customer',
      [`uploadsv3__scanItem__scanError__${FileScanOptionKeys.Virus}`]: 'This file may have a virus. Please a create new document or reach out to the Writer Support team for help.',
      [`uploadsv3__scanItem__scanError__${FileScanOptionKeys.Watermark}`]: 'We are having trouble processing this file: {{scan.error}}',
      [`uploadsv3__scanItem__scanError__${FileScanOptionKeys.FileSize}`]: 'This document is pretty big ({{scan.size}}). Please consider reducing file size on future revisions.',
      [`uploadsv3__scanItem__scanError__${FileScanOptionKeys.AutoQA}`]: 'Quality Scan Failed - Please Proceed',
      uploadsv3__reuploadButton__title: 'Choose which document to reupload',
      uploadsv3__reuploadButton__cancel: 'Cancel',
      uploadsv3__reuploadButton__next: 'Next',
      uploadsv3__bypass__title: 'Are you sure you want to add this document?',
      uploadsv3__bypass__yes: 'Yes',
      uploadsv3__bypass__no: 'No',
      uploadsv3__bypass__body:
        "We've found that high quality first draft is a key predictor of high customer ratings. We recommend editing your drafts in order to receive extra bonuses!",
      uploadsv3__newHirePreUpload__title: 'Has this been reviewed by your Writer Advocate?',
      uploadsv3__newHirePreUpload__body1:
        'It’s imperative that you send your initial drafts to your Writer Advocate for review before sending it to your client.',
      uploadsv3__newHirePreUpload__body2:
        'If you’ve already received the approval, click “Document Reviewed” to proceed to upload.',
      uploadsv3__newHirePreUpload__buttonDocumentReviewed: 'Document Approved',
      uploadsv3__newHirePreUpload__buttonSendToAdvocate: 'Upload for Review',
      uploadsv3__newHirePreUpload__buttonCancel: 'Cancel',
      feature_feedback__bool_yes: 'Yes',
      feature_feedback__bool_no: 'No',
      auto_qa__section_title__word_choice: 'Readability',
      auto_qa__group_title__originality: 'Try restructuring these phrases',
      auto_qa__improvements_group_title__contact_info: "Correct your customer's information",
      auto_qa__section_title__originality: 'Originality',
      auto_qa__section_title__contact_info: 'Contact Information',
      auto_qa__group_title__readability: 'Readability of these sections could have been better.',
      auto_qa__group_tooltip_text__readability:
        'Make sure these bits are as clean and readable as possible. The higher the score, the lower readability',
      auto_qa__readability__score: 'Score: {{ score }}',
      auto_qa__group_title__repetition: 'Were these words used too many times?',
      auto_qa__group_tooltip_text__repetition:
        'We have found repetitive word use can negatively impact customer feedback and general readability. Please be as thoughtful about word choice as you can.',
      auto_qa__group_title__personal: 'Are you sure about this pronoun use?',
      auto_qa__group_tooltip_text__personal:
        'Personal pronouns generally never appear on a resume. Please confirm the use of these words makes sense here.',
      auto_qa__group_title__weak: 'Perhaps consider your word choice here?',
      auto_qa__group_tooltip_text__weak:
        'Weak word choice can undercut the effectiveness of a resume. Please confirm you are being as clear and descriptive as possible.',
      auto_qa__result_explanation__weak: 'Verb too generic, try diversifying your vocabulary:',
      auto_qa__group_title__grammar: 'Bad grammar detected',
      auto_qa__result_title__verbatim: 'Customer Writing Detected',
      auto_qa__group_title__verbatim: 'Should you include the customer’s words here?',
      auto_qa__group_tooltip_text__verbatim:
        'We have found that customers escalate pretty quickly if they read their own words in their freshly rewritten resume.',
      auto_qa__group_title__templates: 'We are seeing template language on this document. Intentional?',
      auto_qa__group_tooltip_text__templates:
        'Sometimes template language can sneak into a new draft. Please make sure this was your intention.',
      auto_qa__result_title__templates: 'Template Language Detected',
      auto_qa__group_title__contact_info: 'Does this customer info look right?',
      auto_qa__group_tooltip_text__contact_info:
        'Typos happen. We figured you’d want to double-check this before sending to your customer.',
      auto_qa__group_tooltip_text__grammar: 'Please review and verify these bits are grammatically correct.',
      auto_qa__result_header__repetition: 'You used "{{stem}}" {{count}} times',
      auto_qa__result_bit_header__repetition: 'Instances',
      auto_qa__result_header__personal: 'We noticed you used pronouns {{score}} times',
      auto_qa__result_bit_header__personal: 'Instances',
      auto_qa__result_title__draft: 'Your draft',
      auto_qa__result_title__original: 'Customer document',
      auto_qa__sectionHeader: '{{title}} ({{count}})',
      auto_qa__file_preprocess__feedback_key__needs_editing: 'Does this need editing?',
      auto_qa__resultsPage__header: 'Auto QA Results for {{filename}}',
      auto_qa__resultsPage__header__general: 'Auto QA Results',
      auto_qa__improvements__item_header: '{{ fileName }} suggestions:',
      documentItemV2__uploadedAtFormat: 'MM/dd/yyyy hh:mm aaaa',
      documentItemV2__uploadedAtShortFormat: 'MM/dd/yy hh:mm aaaa',
      documentItemV2__download: 'Download',
      documentItemV2__delete: 'Delete',
      documentItemV2__autoQAResults: 'View AutoQA Results',
      doucmentItemV2__fileUploadedAt: 'File uploaded at {{time}}',
      documentItemV2__fileReceivedAt: 'File received at {{time}}',
      zipJob__unlimitedRevisions:
        'This client is eligible for a free extended revisions update. If they ask for more time please let FCC know.',
      zipJob__clickHere: 'Click here to learn more.',
      zipJob__unlimitedRevisionsKBArticle:
        'https://intercom.help/talent-fulfillment/en/articles/3982657-zipjob-client-management',
      storeyline_resumes__revisions: '',
      storeyline_resumes__clickHere: '',
      storeyline_resumes__KBArticle: '',
      careerminds__unlimitedRevisions: 'This client is part of an outplacement service. Please review ',
      careerminds__unlimitedRevisions2:
        ' for information about outplacement orders and to review minor changes to the process.',
      careerminds__clickHere: 'this article',
      careerminds__unlimitedRevisionsKBArticle:
        'http://help.experts.talentinc.com/en/articles/6272695-careerminds-placeholder',
      getFive_outplacementNotice: 'This client is part of an outplacement service. Please review ',
      getFive_outplacementNoticeCont:
        ' for information about outplacement orders and to review minor changes to the process.',
      GetFive__clickHere: 'this article',
      getFive_ordersArticle: 'http://help.experts.talentinc.com/en/articles/6526181-get-five-orders',
      careercurve_outplacementNotice: 'This client is part of an outplacement service. Please review ',
      careercurve_outplacementNoticeCont:
        ' for information about outplacement orders and to review minor changes to the process.',
      careercurve__clickHere: 'this article',
      careercurve_ordersArticle: 'http://help.experts.talentinc.com/en/articles/6526442-careercurve-orders',
      fullstack_outplacementNotice: 'This client is from Fullstack Academy. Please review ',
      fullstack_outplacementNoticeCont:
        ' for information about specific adjustments to the process, including required formats.',
      fullstack__clickHere: 'this article',
      fullstack_ordersArticle: 'https://help.experts.talentinc.com/en/articles/7248887-fullstack-academy-orders',

      nudge__message_sent: 'Message sent to {{ clientName }}',
      nudge__first_draft_sent: 'First Draft sent to {{ clientName }}',
      nudge__first_draft_sent__eligible: "Congrats! You've just sent a First Draft. Let's grab a new order!",
      nudge__incentive_earned__eligible:
        "You have earned a {{ incentiveTotalEarned }} {{ incentiveEarnedTypes }}! Let's grab a new order!",
      nudge__incentive_earned: 'You have earned a {{ incentiveTotalEarned }} {{ incentiveEarnedTypes }}!',
      nudge__order_completed: 'Order Completed!',
      nudge__order_completed__eligible: "Order Completed! Let's grab a new order!",
      nudge__items_complete: 'Items Complete!',
      nudge__new_orders: 'Hey! We have orders that fit your preferences.',
      nudge__send_draft: 'Hey! Submit a first draft to one of your orders to get access to new orders.',
      nudge__message_received: '{{ client_name }} sent you a message!',
      nudge__messages_received: '{{ client_name }} sent you {{ unread_count }} messages!',
      documents__editor_upload__rusure_title_a: 'Are you sure you want to approve this document?',
      documents__editor_upload__rusure_title_r: 'Are you sure you want to reject this document?',
      documents__editor_upload__rusure_body:
        'You will not be able to change your decision for this version of the document.',
      documents__editor_upload__rusure_yes: 'Yes',
      documents__editor_upload__rusure_no: 'No',
      documents__editor_upload__decision_approve: 'Approve',
      documents__editor_upload__decision_reject: 'Reject',
      clientPage__side__autolinkedin: 'Auto Linkedin',
      clientPage__side__autolinkedin_edit_cta: 'Edit Linkedin Profile',
      clientPage__side__autolinkedin_start_cta: 'Start Linkedin Profile',
      clientPage__side__autolinkedin_view_cta: 'Get Linkedin Profile',
      clientPage__side__autolinkedin_last_edited: 'Last edited: {{ date }}',
      clientPage__side__autolinkedin_last_published: 'Last published: {{ date }}',
      clientPage__side__autolinkedin_date_placeholder: '-',
      clientPage__side__autolinkedin_date_format: 'MM/dd/yyyy',
      alink__goals: 'Goals',
      alink__workExperience: 'Work Experience',
      alink__education: 'Education',
      alink__publications: 'Publications',
      alink__patents: 'Patents',
      alink__volunteeringExperiences: 'Volunteering Experience',
      alink__certifications: 'Certifications',
      alink__languages: 'Languages',
      alink__honors: 'Honors and Awards',
      alink__organizations: 'Organizations',
      alink__summary_n_headline: 'Headline & Summary',
      alink__industry: 'Industry',
      alink__objective: 'Objective',
      alink__seniority: 'Seniority',
      alink__yearsOfExperience: 'Years of Experience',
      alink__skills: 'Skills',
      alink__workExperience__tooltip:
        'Focus on the client’s accomplishments and their core duties. Feel free to omit details found in the resume / CV; however, be sure to effectively showcase their keywords and career impact.',
      alink__education__tooltip:
        'List the client’s degrees under education. Note: it is not required to list the graduation date.',
      alink__publications__tooltip:
        "The client's publications can be listed in this section. You may have omitted this information from the client's new resume / CV, but it is perfectly acceptable to list it here.",
      alink__patents__tooltip: "List client's patents in this section.",
      alink__volunteeringExperiences__tooltip:
        "If the client has volunteer experience, you can place that information in this section. You may have omitted this information from the client's new resume / CV, but it is perfectly acceptable to list it here.",
      alink__certifications__tooltip:
        'Licenses and certifications can be listed in this section. Note: it is not required to list the issue dates.',
      alink__languages__tooltip: "List the client's language proficiencies in this section.",
      alink__honors__tooltip:
        'If the client has honors or awards, they can be listed in this section even if you have already listed them in context with a job role.',
      alink__organizations__tooltip:
        ' If the client belongs to any professional organizations, they can be listed in this section.',
      alink__headline_n_summary__tooltip:
        'The ideal Headline should include who and what the client is (e.g. Executive Recruiter). As for the Summary, it serves as an elevator pitch that quickly grabs the reader’s attention. Answer these questions for visitors: Who is this person? What do they do? How can they help me?',
      alink__workExperience__companyName: 'Company',
      alink__workExperience__title: 'Title',
      alink__workExperience__location: 'Location',
      alink__workExperience__timePeriod__startMonthYear: 'Start Date',
      alink__workExperience__timePeriod__endMonthYear: 'End Date',
      alink__workExperience__description: 'Description',
      alink__workExperience__current: 'Current Position',
      alink__education__schoolName: 'School',
      alink__education__degreeName: 'Degree',
      alink__education__fieldsOfStudy__name: 'Field of study',
      alink__education__timePeriod__startMonthYear: 'Start Year',
      alink__education__timePeriod__endMonthYear: 'End Year',
      alink__education__activities: 'Activities',
      alink__education__grade: 'Grade',
      alink__education__notes: 'Description',
      alink__nav__title: 'Profile Sections Detected',
      alink__nav__restart: 'Restart the process',
      alink__nav__resume_preview: 'See original resume preview',
      alink__nav__background: 'Background',
      alink__nav__accomplishments: 'Accomplishments',
      alink__publications__title: 'Title',
      alink__publications__publisher: 'Publisher',
      alink__publications__date: 'Publication Date',
      alink__publications__authors__name: 'Authors',
      alink__publications__url: 'Publication URL',
      alink__publications__description: 'Description',
      alink__patents__title: 'Patent Title',
      alink__patents__issuer: 'Patent Issuer',
      alink__patents__applicationNumber: 'Application Number',
      alink__patents__number: 'Number',
      alink__patents__pending: 'Patent Pending',
      alink__patents__inventors__name: 'Author',
      alink__patents__issue_date: 'Issue Date',
      alink__patents__filing_date: 'Filing Date',
      alink__patents__url: 'Patent URL',
      alink__patents__description: 'Description',
      alink__volunteeringExperiences__companyName: 'Organization',
      alink__volunteeringExperiences__role: 'Role',
      alink__volunteeringExperiences__cause: 'Cause',
      alink__volunteeringExperiences__timePeriod__startMonthYear: 'Start Date',
      alink__volunteeringExperiences__timePeriod__endMonthYear: 'End Date',
      alink__volunteeringExperiences__description: 'Description',
      alink__certifications__name: 'Name',
      alink__certifications__authority: 'Issuing Organization',
      alink__certifications__current: "This credential doesn't expire",
      alink__certifications__timePeriod__startMonthYear: 'Issue Date',
      alink__certifications__timePeriod__endMonthYear: 'Expiry Date',
      alink__certifications__licenseNumber: 'Credential ID',
      alink__certifications__url: 'Credential URL',
      alink__languages__name: 'Language',
      alink__languages__proficiency: 'Proficiency',
      alink__honors__title: 'Title',
      alink__honors__issueDate: 'Issue Date',
      alink__honors__issuer: 'Issuer',
      alink__honors__description: 'Description',
      alink__organizations__name: 'Name',
      alink__organizations__position: 'Position Held',
      alink__organizations__timePeriod__startMonthYear: 'Start Date',
      alink__organizations__timePeriod__endMonthYear: 'End Date',
      alink__organizations__description: 'Description',
      alink__summary__text: 'Summary',
      alink__headline: 'Headline',
      alink__button__looksGood: 'Looks Good',
      alink__button__update: 'Update',
      alink__button__delete: 'Delete',
      alink__button__saveChanges: 'Save Changes',
      alink__PickResume__title: 'Select a resume to parse for {{ clientName }}',
      alink__PickResume__cancel: 'Cancel',
      alink__PickResume__next: 'Next',
      alink__EditLIProfileSectionsJobDesc__title: 'Do these job descriptions work for LinkedIn use?',
      alink__EditLIProfileSections__title: 'Does everything look right?',
      alink__ComposeSummary__title: 'Now, create a Linkedin Summary',
      alink__Preview__title: 'One final look. All good to go?',
      alink__Preview__next: 'Next',
      alink__Preview__next__publishing: 'Generating Document...',
      alink__Preview__back: 'Back',
      alink__PickResume__upload: "Can't find your file? Try uploading instead",
      alink__PickResume__startManually: "Client didn't provide a resume? Start manually",
      alink__PickResume__getDoc: 'We recommend getting a copy of the client’s resume for this LinkedIn process',
      alink__RevisionSelection__title: 'Continue from the autosave version or start from a previous revision',
      alink__RevisionSelection__cancel: 'Cancel',
      alink__RevisionSelection__next: 'Next',
      alink__RevisionSelection__autosave: 'Autosave version',
      alink__RevisionSelection__revision: 'Revision #{{ revision }}',
      alink__RestartDialog__question: 'Are you sure about restarting?',
      alink__AbandonDialog__question: 'Are you sure about leaving?',
      alink__DeleteRecord__question: 'Are you sure about deleting?',
      alink__AbandonDialog__body:
        'Don’t worry, everything has been autosaved. You can always come back and continue working on this later.',
      alink__ComposeSummary__cancel: 'Back',
      alink__ComposeSummary__next: 'Next',
      alink__EditLIProfileSections__cancel: 'Cancel',
      alink__EditLIProfileSections__next: 'Next',
      alink__EditLIProfileSections__saving: 'Saving...',
      alink__EditLIProfileSections__last_saved: 'Last Autosaved {{ time }}',
      alink__EditLIProfileSectionsJobDesc__next_tooltip:
        'Make sure all job descriptions <b>look good</b> for a LinkedIn profile before moving forward!',
      alink__EditLIProfileSectionsFormInvalid__next_tooltip:
        'Please check the forms to ensure they have the information they need for LinkedIn use!',
      alink__generateError: 'We had trouble converting the selected document. Please try again',
      alink__updateError: 'Update failed. Please try again',
      alink__updateSystemError: "We don't accept phrase system(), please edit your message.",
      alink__publishError: 'Document generation failed. Please try again',
      alink__PublishedConfirmation__title: "Great! You're all done",
      alink__PublishedConfirmation__body: "{{ client_name }}'s LinkedIn Profile is Ready to Go!",
      alink__PublishedConfirmation__download: 'Download a Copy',
      alink__PublishedConfirmation__attach: 'Proceed To Attach',
      alink__error__required: 'Required',
      alink__error__limit_exceeded: 'You’ve exceeded the limit by {{ diff }} characters',
      secondaryDocBlock__upload_more: 'Upload more files',
      secondaryDocBlock__upload_without: 'Proceed to Scan',
      secondaryDocBlock__title: 'Hold on, what about {{ missing_files }}?',
      secondaryDocBlock__body1: 'Looks like the client also included {{ missing_files }} in their order.',
      secondaryDocBlock__body2:
        'Writers that send over secondary documents along with their first draft close orders faster and get a better CSAT score. Are you sure you want to proceed?',
      documents__delete__blocker: 'Are you sure you want to permanently delete this file?',
      aresearch__tasks__header: 'Tasks:',
      aresearch__tech__header: 'Technical Competencies:',
      aresearch__detailed__work: 'Detailed Work Activities:',
      aresearch__short__summary: 'Short Summary:',
      aresearch__saved__search: 'Saved Search:',
      aresearch__job__goals: 'Job goals',
      aresearch__job__goal: 'Client job goals',
      aresearch__job__goal_btn: 'Open goal research',
      aresearch__job__history: 'Job History #',
      aresearch__job__title: 'Title:',
      aresearch__job__title_parsed: 'Title (parsed):',
      aresearch__job__title_std: 'Title (standard):',
      aresearch__job__employer: 'Employer:',
      aresearch__job__duration: 'Duration:',
      beta__test: 'BETA',
      aresearch__search__title_Replace: 'Replace {{ occupation }} with...',
      aresearch__search__title_Add: 'Which job titles would you like to add?',
      aresearch__search__see_more: 'See more',
      aresearch___client__job__delete__title: 'Are you sure you want to delete this job?',
      aresearch___client__job__delete__subtitle: 'You will not be able to restore it.',
      aresearch___client__job__reset__title: 'Reset this job history?',
      aresearch___client__job__reset__subtitle: 'You can always add or edit your inputs later.',
      aresearch__search__no_result: 'No results',
      aresearch__search__added: 'Added!',
      aresearch__delete: 'Delete',
      aresearch__learn_More: 'Learn More',
      aresearch__option: 'Find a Better Option',
      aresearch__reverse: 'Reverse Change',

      dashboard__schedule__title: 'Your Calendar',
      schedule_calendar_title: 'Calendar',
      schedule_unfulfilled_title: 'unfulfilled',
      schedule_setup_message: 'Setup your schedule to receive orders with phone calls.',
      schedule_setup_now: 'Setup now',
      schedule_calendar_skip: 'Skip',

      schedule_calendar_start_end: 'When does your work day start and end?',
      schedule_calendar_start: 'Day starts at',
      schedule_calendar_end: 'Day ends at',
      schedule_calendar_next: 'Next',
      schedule_calendar_invalid: 'Invalid time format, expected format HH:MM',
      schedule_calendar_invalid_start: 'Day ends must be after day starts',

      schedule_calendar_buffer_info: 'When does your work day start and end?',
      schedule_calendar_buffer: 'Call buffer time',
      schedule_calendar_min: 'Min. booking notice',
      schedule_calendar_reminder: 'Would you like to be notified of events via email?',
      schedule_calendar_set: 'Set reminders via',
      schedule_calendar_notified: 'Get notified via email',
      schedule_calendar_email: 'Email',
      schedule_calendar_sms: 'SMS',
      schedule_calendar_finish: 'Finish',
      schedule_calendar_weekend: 'Show weekends',
      schedule_calendar_link: 'Link calendar',
      schedule_calendar_unlink: 'Unlink calendar',

      schedule_unfulfilled_unassigned: 'Unassigned',
      schedule_unfulfilled_missed: 'Missed',
      schedule_unfulfilled_reschedule: 'Reschedule',
      schedule_unfulfilled_missed_call: 'you’ve missed this call',
      schedule_calendar_next_week: 'Next week',
      schedule_calendar_prev_week: 'Last week',
      schedule_calendar_linked: 'Linked calendar',
      schedule_calendar_busy: 'Marked busy',
      schedule_calendar_call: 'Calls scheduled',
      schedule_calendar_draft: 'Drafts due',
      schedule_calendar_available: 'Available',
      schedule_calendar_unavailable: 'Unavailable',
      schedule_calendar_today: 'Today',
      schedule_calendar_weekly: 'Week',
      schedule_calendar_day: '3 Day',
      schedule_calendar_workweek: 'Work Week',
      schedule_calendar_call_in: 'Call',
      schedule_calendar_no_call: 'No calls today',
      schedule_calendar_setting: 'Calendar Settings',
      schedule_calendar_in: 'in ',
      schedule_calendar_start_now: 'Starts now',
      schedule_calendar_in_hr: 'hr',
      schedule_calendar_in_min: 'min',
      schedule_calendar_in_day: 'day',
      schedule_calendar_in_days: 'days',
      schedule_calendar_missed: 'Call missed',
      schedule_calendar_save: 'Save',
      schedule_calendar_primary_calendar: 'Do you want Talent Inc events to show up on your own calendar?',
      schedule_calendar_unlink_calendar: 'Are you sure you want to unlink?',
      schedule_calendar_unlink_primary:
        'We will no longer be able to manage any customer events that you have scheduled on your personal calendar.',
      schedule_calendar_unlink_change:
        "Without it linked, we won't be able to use it to determine your availability when scheduling customer calls.",
      schedule_calendar_low_availability: 'Proceed with low availability?',
      schedule_calendar_low_availability_body:
        'In order to accept new orders with phone calls, you must have availability of at least 6 hours a week over 2 days. This is to ensure your customers can find a time to connect with you.',
      schedule_order_low_availability: 'In order to start seeing options for orders with phone calls, you need to',
      schedule_order_low_availability_link: ' increase your availability.',
      schedule_not_setup_link: ' setup your schedule.',
      schedule_order_phone_call: 'Phone call',
      schedule_order_interview: 'Interview Coaching',
      schedule_order_join_call_cta: 'Join now',
      schedule_order_join_missed_cta: 'Missed',
      schedule_order_send_invite_cta: 'Send Invite',
      schedule_order_awaiting_customer_cta: 'Awaiting Customer',
      schedule_order_reschedule_cta: 'Reschedule',
      schedule_order_start_call_cta: 'Start Call',
      schedule_order_send_nudge_cta: 'Send Nudge',
      schedule_order_transcript_cta: 'View Transcript',
      schedule_order_audio_cta: 'Download Audio Recording',
      schedule_order_video_cta: 'Download Video Recording',
      schedule_order_nudge_sent: 'Nudge Sent',
      schedule_order_side_call_history: 'Call history:',
      schedule_order_side_call_scheduled: 'Call Scheduled',
      schedule_order_side_call_history_duration: '{{duration}}m ',
      schedule_order_send_note:
        'Clients will have the ability to book a 30 min time block based on your phone call preferences',
      schedule_order_nudge_note: 'Give the client time to respond. We’ll send another nudge in 2 days.',
      schedule_order_transcript_note: 'Here’s a recap to give your clients or for your own documentation purposes.',
      schedule_zoom_call_join: 'How would you like to join?',
      schedule_zoom_call_join_sub: 'This does not affect the call or how the client takes the call',
      schedule_zoom_call_browser: 'Zoom via browser',
      schedule_zoom_call_app: 'Open the Zoom App',
      schedule_zoom_call_phone: 'Join by phone',
      dashboard__macro__phonecall__linkText: '{{callsCount}} phone calls ',
      dashboard__macro_phonecall_week_bodyText: 'this week',
      dashboard__macro_phonecall_today_bodyText: 'today',
      dashboard__macro_phonecall_tomorrow_bodyText: 'tomorrow',
      messaging__scheduling__header: 'Propose Service Time',
      messaging__scheduling__submit: 'Update',
      messaging__scheduling__startAt: 'Session Start Time',
      messaging__scheduling__send: 'Send',
      messaging__scheduling__preview: 'Preview',
      messaging__scheduling__at: 'at',
      messaging__scheduling__dateTimeLabel: 'Call Date & Time',
      messaging__scheduling__noteLabel: 'Note (optional)',
      messaging__scheduling__sessionLabel: 'Select session',
      messaging__scheduling__scheduled_label: 'Scheduled for {{dateTime}}',
      messaging__scheduling__proposed_label: 'Invite sent for {{dateTime}}',
      messaging__availability_save: 'Toggle your availability by clicking on a timeslot',
      messaging__malware_scan_title: 'Malware Detected',
      messaging__malware_scan_body: `There's a link in the message that contains malware, remove it to continue.`,
      directSched__error__date: 'Date and time should be in the future',
      directSched__error__date_invalid: 'Invalid date format',
      aiDraft_popup_loading: 'Gathering data for AI Draft. Please be patient.',
      aiDraft_popup_success:
        'We’ll get started on the AI resume. You’ll receive an Expert Hub notification when the draft is ready for you.',
      aiDraft_popup_error: 'Sorry there was an issue generating your AI draft. Please try again later.',
      aiDraft_popup_unknown_error: 'Something went wrong, Please try refershing the page.',
      aiDraft_popup_ques_needChange:
        'Before we generate an AI Draft, we need to know if the client desires to change their career in their new resume?',
      aiDraft_popup_ques_occupation: 'What occupation does the client desire for their new resume?',
      aiDraft_popup_ques_occupation_placeholder: 'Type to search...',
      aiDraft_popup_submit: 'Submit',
      order_close_popup_text: 'Please verify all documents are uploaded and ready for delivery.',
    },
  },
  de: {
    translation: {
      date_format: 'MMMM dd, yyyy',
      time_format: 'hh:mm a',
      emailTemplate__replyAboveThisLine: 'Bitte geben Sie Ihre Antwort über dieser Zeile ein',
      emailTemplate__reviewYourOrder: 'Bestellung überprüfen',
      emailTemplate__yourFiles: 'Ihre Datein',
    },
  },
  fr: {
    translation: {
      date_format: 'MMMM dd, yyyy',
      time_format: 'hh:mm a',
      emailTemplate__replyAboveThisLine: 'Merci de répondre à votre rédacteur au-dessus de cette ligne',
      emailTemplate__reviewYourOrder: 'Vérifier votre commande',
      emailTemplate__yourFiles: 'Répondre',
    },
  },
}
/* eslint-enable @typescript-eslint/camelcase */

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function(value, format, lng) {
        if (format === 'fractionalDays') {
          return Math.round((value + Number.EPSILON) * 10) / 10
        }

        return value
      },
    },
  })

export default i18n
