import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import NoteIcon from '@material-ui/icons/EventNote'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'

import ClientInfoContainer from '../../common/ClientInfoContainer'
import { formatDate, formatOrderDueDateForDetails } from '../../../utils/formatting'
import useUserInfoState from '../../common/useUserInfo'
import { Note } from '../../../store/notes/types'
import { useOrderCarousel } from '../SideBar/useOrderCarousel'
import useStickyHeader from './useStickyHeader'
import i18n from '../../../i18n'
import styles from '../../../styles'
import ZipJobLogo from '../../../assets/zipjob-logo.png'
import LaddersLogo from '../../../assets/logo-theladders_tr.png'
import CareermindsLogo from '../../../assets/logo-careerminds.png'
import StoreylineResumesLogo from '../../../assets/logo-storeyline-resumes.png'
import GetFiveLogo from '../../../assets/logo-GetFive.svg'
import CareerCurveLogo from '../../../assets/logo-careercurve.svg'
import FullstackAcademyLogo from '../../../assets/logo-fullstack-academy.svg'
import LightTooltip from '../../common/LightTooltip'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store'
import { Client } from '../../../store/clients/reducer'
import { clientByIdSelector } from '../../../selectors/clients'

interface HeaderProps {
  clientID: number
  notes?: Note[]
  isSticky?: boolean
}

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.6rem 1rem 0.6rem 2rem',
    marginBottom: '1.125rem',
    backgroundColor: '#fff',
    border: '1px solid #D5D9E0',
    width: 'calc(100% - 19rem - 1.5rem)',
    '@media (max-width: 1024px)': {
      width: '100%',
    },
    '@media (max-width: 740px)': {
      padding: '0.5rem 1rem',
    },
  },
  stickyHeader: {
    position: 'fixed',
    width: '100vw',
    top: '0',
    left: '0',
    zIndex: 2,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    position: 'relative',
    '& .status': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginLeft: '2.1rem',
      '& .deadline': {
        color: styles.palette.lightBlack.color,
        fontSize: '0.8125rem',
        lineHeight: '0.98rem',
        '& .late': {
          color: '#f64628',
        },
      },
      '@media (max-width: 680px)': {
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: 0,
      },
    },
  },
  notesTrigger: {
    minWidth: 0,
    marginRight: '.5rem',
    padding: '.375rem',
    '& .text': { lineHeight: '1rem' },
    '& .icon': { color: '#f64628', marginLeft: '.1rem' },
  },
  notes: {
    marginBottom: '1.125rem',
    width: 'calc(100% - 19rem - 1.5rem)',
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  note: {
    padding: '.75rem',
    backgroundColor: '#fff',
    border: '1px solid #f64426',
    borderRadius: '3px',
    '&:not(:last-child)': { marginBottom: '.625rem' },
    '& .text': {
      fontSize: '.8125rem',
      color: styles.palette.lightBlack.color,
      whiteSpace: 'pre-line',
    },
    '& .info': {
      marginTop: '.5rem',
      fontSize: '.6875rem',
      color: '#3f3f3f',
      textAlign: 'right',
    },
    '&.user-client': {
      borderColor: 'black',
    },
  },
  zipJobLogo: {
    height: '1.5rem',
  },
  testOrderFlag: {
    fontWeight: 600,
    color: '#f26d6d',
  },
}))

const Header: React.FC<HeaderProps> = ({ clientID, notes = [], isSticky = false }) => {
  const classes = useStyles()
  const [viewNotes, setViewNotes] = useState(false)
  const { sticky, setEnabled, headerRef, enabled } = useStickyHeader()
  const { timeZone, locale } = useUserInfoState()
  const { currentOrderGroup } = useOrderCarousel(clientID)
  const [formattedDate, orderIsLate] = currentOrderGroup.macroState.dueDate
    ? formatOrderDueDateForDetails(
        new Date(currentOrderGroup.macroState.dueDate),
        timeZone,
        locale,
        currentOrderGroup.macroState.CTA
      )
    : ['', false]
  const isZipJob = currentOrderGroup.brand.name === 'ZipJob'
  const isLadders = currentOrderGroup.brand.description === 'The Ladders'
  const isCareerminds = currentOrderGroup.brand.name === 'Careerminds'
  const isStoreylineResumes = currentOrderGroup.brand.name === 'Storeyline Resumes'
  const isGetFive = currentOrderGroup.brand.name === 'Get Five'
  const isCareerCurve = currentOrderGroup.brand.name === 'CareerCurve'
  const isFullstackAcademy = currentOrderGroup.brand.name === 'Fullstack - TopResume'
  const client = useSelector<AppState, Client>(store => clientByIdSelector(store.clientReducer, clientID))
  useEffect(() => {
    if (enabled !== isSticky) {
      setEnabled(isSticky)
    }
  }, [setEnabled, isSticky, enabled])

  return (
    <>
      <div className={classNames({ [classes.stickyHeader]: sticky })} ref={headerRef}>
        <Box className={classNames({ [classes.header]: true })} component={'header'}>
          <Box className={classes.info}>
            <ClientInfoContainer clientID={clientID} hideAvatar={true} />
            {formattedDate && (
              <Box className="status">
                <Box className={classNames({ deadline: true })}>
                  {formattedDate}
                  {orderIsLate && (
                    <Typography
                      display="inline"
                      className={classNames({ deadline: true, late: orderIsLate })}
                      area-label="head_order_deadline"
                    >
                      {` - ${i18n.t('orders__due_date__details_view_late')}`}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
            {client.test_order === 1 && client.outplacement_order === 0 && (
              <Box className="status">
                <Typography className={classes.testOrderFlag}>Test Order</Typography>
              </Box>
            )}
          </Box>
          {notes.length > 0 && !sticky && (
            <Button className={classes.notesTrigger} onClick={() => setViewNotes(!viewNotes)}>
              <Typography display="inline" variant={'body1'} className="text" area-label="notes_trigger">
                {notes.length}
              </Typography>
              <NoteIcon className="icon" />
            </Button>
          )}
          {isZipJob && (
            <LightTooltip
              interactive
              title={
                <>
                  {i18n.t('zipJob__unlimitedRevisions')}{' '}
                  <a href={i18n.t('zipJob__unlimitedRevisionsKBArticle')} target="_blank" rel="noopener noreferrer">
                    {i18n.t('zipJob__clickHere')}
                  </a>
                </>
              }
              placement="right"
            >
              <img src={ZipJobLogo} className={classes.zipJobLogo} alt="ZipJob logo" />
            </LightTooltip>
          )}
          {isLadders && (
            <LightTooltip title={<> </>} placement="right">
              <img src={LaddersLogo} className={classes.zipJobLogo} alt="The Ladders logo" />
            </LightTooltip>
          )}
          {isCareerminds && (
            <LightTooltip
              interactive
              title={
                <>
                  {i18n.t('careerminds__unlimitedRevisions')}
                  <a
                    href={i18n.t('careerminds__unlimitedRevisionsKBArticle')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {i18n.t('careerminds__clickHere')}
                  </a>
                  {i18n.t('careerminds__unlimitedRevisions2')}
                </>
              }
              placement="right"
            >
              <img src={CareermindsLogo} className={classes.zipJobLogo} alt="Careerminds logo" />
            </LightTooltip>
          )}
          {isStoreylineResumes && (
            <LightTooltip
              interactive
              title={
                <>
                  {i18n.t('storeyline_resumes__revisions')}{' '}
                  <a href={i18n.t('storeyline_resumes__KBArticle')} target="_blank" rel="noopener noreferrer">
                    {i18n.t('storeyline_resumes__clickHere')}
                  </a>
                </>
              }
              placement="right"
            >
              <img src={StoreylineResumesLogo} className={classes.zipJobLogo} alt="StoreylineResumes Logo" />
            </LightTooltip>
          )}
          {isGetFive && (
            <LightTooltip
              interactive
              title={
                <>
                  {i18n.t('getFive_outplacementNotice')}
                  <a href={i18n.t('getFive_ordersArticle')} target="_blank" rel="noopener noreferrer">
                    {i18n.t('GetFive__clickHere')}
                  </a>
                  {i18n.t('getFive_outplacementNoticeCont')}
                </>
              }
              placement="right"
            >
              <img src={GetFiveLogo} className={classes.zipJobLogo} alt="GetFive logo" />
            </LightTooltip>
          )}
          {isCareerCurve && (
            <LightTooltip
              interactive
              title={
                <>
                  {i18n.t('careercurve_outplacementNotice')}
                  <a href={i18n.t('careercurve_ordersArticle')} target="_blank" rel="noopener noreferrer">
                    {i18n.t('careercurve__clickHere')}
                  </a>
                  {i18n.t('careercurve_outplacementNoticeCont')}
                </>
              }
              placement="right"
            >
              <img src={CareerCurveLogo} className={classes.zipJobLogo} alt="CareerCurve logo" />
            </LightTooltip>
          )}
          {isFullstackAcademy && (
            <LightTooltip
              interactive
              title={
                <>
                  {i18n.t('fullstack_outplacementNotice')}
                  <a href={i18n.t('fullstack_ordersArticle')} target="_blank" rel="noopener noreferrer">
                    {i18n.t('fullstack__clickHere')}
                  </a>
                  {i18n.t('fullstack_outplacementNoticeCont')}
                </>
              }
              placement="right"
            >
              <img src={FullstackAcademyLogo} className={classes.zipJobLogo} alt="Fullstack - TopResume" />
            </LightTooltip>
          )}
        </Box>
      </div>
      {viewNotes && notes.length > 0 && (
        <Box className={classes.notes}>
          {notes.map(note => (
            <Box className={classNames([classes.note, note.context])} key={`note-${note.id}`}>
              <Typography display="block" variant={'h6'} className="text">
                {note.note}
              </Typography>
              <Typography display="block" variant={'h6'} className="info">
                {`${note.author} ${formatDate(note.created_at, timeZone, locale, 'MM/dd/yy hh:mm a')}`}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}

const memoizedHeader = React.memo(Header, isEqual)
// @ts-ignore
memoizedHeader.whyDidYouRender = true

export default memoizedHeader
